<template>
    <div class="container">
      <div class="info">正在登出...</div>
    </div>
  </template>
  
  <script>
  export default {
    beforeCreate() {
        this.$store.commit('user/setAccessToken', "")
        this.$router.push('/')
    }
  }
  </script>
  
  <style scoped>
  .container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .info {
    margin-bottom: 40px;
  }
  </style>